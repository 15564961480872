@import url('azure-maps-control/dist/atlas.min.css');

.map {
    width: 100%;
    height: 100vh;
    min-width: 150px;
    min-height:150px;
    z-index: 0;
}

.routeInfoBlock {
    position:absolute;
    top:10px;
    left:10px;
    background-color:white;
    border-radius:5px;
    padding:6px;
    z-index: 5;
    font: 14px/1.3 "Helvetica Neue", Arial, Helvetica, sans-serif;
    border: 1px solid black;
}

.leaflet-popup-close-button {
    display: none; 
 }

.leaflet-div-icon {
	background: transparent;
	border: none;
}

.leaflet-div-icon .number{
	position: relative;
	top: -41px;
	font-size: 11px;
	width: 25px;
	text-align: center;
}